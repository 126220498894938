<template>
  <div class="map-results__body-table--simple">
    <div class="table-row" v-for="(item, i) in results" :key="`row-${i}`">
      <div class="table-row__title">
        {{ item.title }}
      </div>
      <div class="table-row__items">
        <div class="item" v-for="(subItem, n) in item.items" :key="`row-${i}--item-${n}`">
          <div class="item__text">{{ subItem.text }}</div>
          <div class="item__val">{{ subItem.val }} {{ subItem.unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  name: 'ResultsTableSimplified'
}
</script>

<style scoped lang="sass">
.map-results
  &__body
    &-table
      &--simple
        +media((margin-top: (320: rem(12), 768: rem(16))))
        color: $black_A
        display: grid
        grid-template-columns: 1fr
        row-gap: rem(32)
        .table-row

          &:not(:last-child)
            padding-bottom: rem(32)
            box-shadow: inset 0 rem(-1) 0 $cool_B
          &__title
            width: 100%
            // grid-column: 1 / 13
            @extend %14
            font-weight: bold
            +media((margin-bottom: (320: rem(14), 768: rem(28))))
          &__items
            display: grid
            $t-s: repeat(auto-fit, minmax(rem(80), 1fr))
            $t-l: repeat(auto-fit, minmax(rem(84), 1fr))
            +media((grid-template-columns: (320: $t-s, 768: $t-l)))
            row-gap: rem(24)
            .item
              @extend %14
              display: flex
              flex-direction: column
              justify-content: space-between
              &__text
                font-weight: bold
                +media((margin-bottom: (320: rem(6), 768: rem(16))))
</style>
